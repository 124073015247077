import createDialogSingleton, { DialogProviderProps } from '../../../sci-ui-components/utils/createDialogSingleton';
import { AddOrEditCollectionItemDialogInfoProps, AddOrEditCollectionItemDialogResult } from './types';

const { invokeDialog: addOrEditCollectionItem, registerDialog } = createDialogSingleton<
  AddOrEditCollectionItemDialogInfoProps,
  AddOrEditCollectionItemDialogResult
>();

export { addOrEditCollectionItem, registerDialog };
export type AddOrEditCollectionItemDialogProviderProps = DialogProviderProps<
  AddOrEditCollectionItemDialogInfoProps,
  AddOrEditCollectionItemDialogResult
>;
