import { CollectibleType } from '../../../../sci-ui-components/types/collectibleType';
import { FavoriteCollectible } from '../../../../sci-ui-components/types/favoriteCollectible';
import { ApiCollectibleFavorite, ApiCollectibleFavoriteSportsCard, ApiCollectibleFavoriteSealedWax } from '../../types';

export default function favoriteCollectibleFromApi(
  apiItem: ApiCollectibleFavorite,
  params: { collectibleType: CollectibleType }
): FavoriteCollectible {
  const apiStats = getStats(apiItem, params);
  return {
    id: apiItem.foreign_id,
    favoriteId: apiItem.id,
    categoryId: apiItem.category_id,
    favoriteHistoricalStats: {
      lastSale: apiStats?.last_sale ?? null,
      last30ChangePercentage: apiStats?.last30 ?? null,
      last7ChangePercentage: apiStats?.last7 ?? null,
      last90ChangePercentage: apiStats?.last90 ?? null,
      lastAveragePrice: apiStats?.last_avg_price ?? null,
    },
  };
}

function getStats(apiItem: ApiCollectibleFavorite, { collectibleType }: { collectibleType: CollectibleType }) {
  switch (collectibleType) {
    case 'sports-card': {
      return (apiItem as ApiCollectibleFavoriteSportsCard).card?.historical_stats;
    }
    case 'sealed-wax-card': {
      return (apiItem as ApiCollectibleFavoriteSealedWax).sealed_wax?.sealed_wax_stats;
    }
    default:
      return null;
  }
}
