import qs from 'qs';
import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import { FavoriteCollectible } from '../../../sci-ui-components/types/favoriteCollectible';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiCollectibleFavorite, ApiFavoriteCategory } from '../types';
import favoriteCollectibleFromApi from './transformers/favoriteCollectibleFromApi';
import { getApiCollectibleType } from './utils';

export interface GetCollectibleFavoritesResponse {
  favorites: FavoriteCollectible[];
  totalCount: number;
}

export async function getCollectibleFavorites(
  {
    collectibleType,
    categoryId,
    searchText,
    sportId = null,
    offset = 0,
    limit = 10,
  }: {
    collectibleType: CollectibleType;
    categoryId: number;
    searchText?: string | null;
    sportId?: number | null;
    offset?: number;
    limit?: number;
  },
  signal: AbortSignal | undefined
): Promise<GetCollectibleFavoritesResponse> {
  const type = getApiCollectibleType(collectibleType);
  const { data: apiItems, totalCount } = await authenticatedFetchFromSciApi<{
    data: ApiCollectibleFavorite[];
    totalCount: number;
  }>(
    `/favorite-categories/${categoryId}/favorites${qs.stringify(
      {
        type,
        title: searchText,
        sportId,
        offset,
        limit,
      },
      {
        addQueryPrefix: true,
        skipNulls: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );

  const favorites = apiItems?.map((item) => favoriteCollectibleFromApi(item, { collectibleType })) ?? [];

  return {
    favorites,
    totalCount,
  };
}

export interface FavoriteCollectibleIds {
  collectibleId: number;
  favoriteId: number;
}

export async function getAllCollectibleFavoritesIds(
  { collectibleType }: { collectibleType: CollectibleType },
  signal: AbortSignal | undefined
) {
  const type = getApiCollectibleType(collectibleType);
  const shallowFavorites = await authenticatedFetchFromSciApi<
    {
      id: number;
      foreign_id: number;
      type: string;
    }[]
  >(
    `/favorite/${type}${qs.stringify(
      {
        full: false,
      },
      {
        addQueryPrefix: true,
        skipNulls: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );

  return shallowFavorites.map<FavoriteCollectibleIds>(({ foreign_id, id }) => ({
    collectibleId: foreign_id,
    favoriteId: id,
  }));
}

export interface AddFavoriteCollectibleParams {
  collectibleType: CollectibleType;
  categoryId: number;
  collectibleId: number;
}

export async function addFavoriteCollectible({
  collectibleType,
  categoryId,
  collectibleId,
}: AddFavoriteCollectibleParams) {
  const type = getApiCollectibleType(collectibleType);
  await authenticatedFetchFromSciApi<ApiFavoriteCategory>('/favorite', {
    method: 'POST',
    body: JSON.stringify({
      category_id: categoryId,
      foreign_id: collectibleId,
      type,
    }),
  });
}

export interface MoveFavoriteCollectibleToCategoryParams {
  categoryId: number;
  favoriteId: number;
}

export async function moveFavoriteCollectibleToCategory({
  categoryId,
  favoriteId,
}: MoveFavoriteCollectibleToCategoryParams) {
  await authenticatedFetchFromSciApi<ApiFavoriteCategory>(`/favorites/${favoriteId}`, {
    method: 'PUT',
    body: JSON.stringify({
      category_id: categoryId,
    }),
  });
}

export interface RemoveCollectibleFromFavoritesParams {
  favoriteIds: number[];
}

export async function removeCollectibleFromFavorites({ favoriteIds }: RemoveCollectibleFromFavoritesParams) {
  await authenticatedFetchFromSciApi<ApiFavoriteCategory>('/favorite', {
    method: 'DELETE',
    body: JSON.stringify({
      favorites: favoriteIds,
    }),
  });
}
