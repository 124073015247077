import { useCallback, useMemo } from 'react';
import { useQueries, useQuery, UseQueryOptions } from 'react-query';
import { ApiError } from '../../utils/api';
import { CollectibleType, collectibleTypes } from '../../sci-ui-components/types/collectibleType';
import {
  CollectionOverallStats,
  CollectionOverallStatValues,
} from '../../sci-ui-components/types/collectionOverallStats';
import { getCollectionStats } from '../../services/sciApi/collections/index';
import useAuth from '../auth/useAuth';
import { CollectibleOption } from '../collectibles/constants';
import combineCollectionStats from './helpers/combineCollectionStats';

export const keyPrefix = 'collection-stats';
const getQueryKey = ({
  collectibleType,
  categoryId,
}: {
  collectibleType: CollectibleType;
  categoryId?: number | null;
}) => [keyPrefix, collectibleType, categoryId];
type Key = ReturnType<typeof getQueryKey>;

export function useCollectionStats(
  {
    collectibleType,
    categoryId,
  }: {
    collectibleType: CollectibleType;
    categoryId?: number | null;
  },
  options: UseQueryOptions<CollectionOverallStats, ApiError, CollectionOverallStats, Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery(
    getQueryKey({ collectibleType, categoryId }),
    ({ signal }) =>
      getCollectionStats(
        {
          collectibleType,
          categoryId,
        },
        signal
      ),
    {
      ...options,
      enabled: isLoggedIn && (options?.enabled ?? true),
    }
  );

  return queryResult;
}

export type CollectionOverallStatValuesByType = Record<CollectibleOption, CollectionOverallStatValues>;

export function useGlobalCollectionStats(
  {
    categoryId,
  }: {
    categoryId?: number;
  } = {},
  options: UseQueryOptions<CollectionOverallStats, ApiError, CollectionOverallStats, Key> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResults = useQueries(
    collectibleTypes.map((collectibleType) => ({
      ...options,
      queryKey: getQueryKey({ collectibleType, categoryId }),
      queryFn: () =>
        getCollectionStats(
          {
            collectibleType,
            categoryId,
          },
          undefined
        ),
      enabled: isLoggedIn && (options?.enabled ?? true),
    }))
  );

  const isLoading = queryResults.some((qr) => qr.isLoading);
  const isError = queryResults.some((qr) => qr.isError);
  const error = queryResults.find((qr) => qr.error);
  const refetch = useCallback(() => {
    queryResults.forEach(({ refetch }) => refetch());
  }, [queryResults]);

  const globalStats = useMemo<CollectionOverallStatValuesByType | null>(() => {
    if (isLoading) {
      return null;
    }
    return collectibleTypes.reduce<CollectionOverallStatValuesByType>((acc, collectibleType, index) => {
      const stats = queryResults[index].data;
      acc.all = combineCollectionStats([acc.all, stats?.custom, stats?.nonCustom]);
      acc[collectibleType] = combineCollectionStats([acc[collectibleType], stats?.custom, stats?.nonCustom]);
      return acc;
    }, {} as CollectionOverallStatValuesByType);
  }, [isLoading, queryResults]);

  return {
    queryResults,
    globalStats,
    isLoading,
    isError,
    error,
    refetch,
  };
}
