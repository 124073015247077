import type { MembershipTier, User } from '../auth/types';

// TODO: incorporate collectible type into permissions config
export type MembershipTierLimitType = 'collection' | 'alerts' | 'favorites' | 'dateRanges' | 'waxChartsDateRange';
export type MembershipTierFeature = 'waxCollection';
export type MembershipTierPermissionCategory = MembershipTierLimitType | MembershipTierFeature;

type MembershipTierLimitsConfig = {
  [key in MembershipTierLimitType]: Record<MembershipTier, number>;
};

type MembershipTierFeaturesConfig = {
  [key in MembershipTierFeature]: Record<MembershipTier, boolean>;
};

export const membershipTierLimits: MembershipTierLimitsConfig & MembershipTierFeaturesConfig = {
  collection: {
    starter: 25,
    premium: 250,
    unlimited: Infinity,
  },
  waxCollection: {
    starter: false,
    premium: true,
    unlimited: true,
  },
  alerts: {
    starter: 5,
    premium: 20,
    unlimited: Infinity,
  },
  favorites: {
    starter: 10,
    premium: Infinity,
    unlimited: Infinity,
  },
  dateRanges: {
    starter: 30,
    premium: 90,
    unlimited: Infinity,
  },
  waxChartsDateRange: {
    starter: 30,
    premium: Infinity,
    unlimited: Infinity,
  },
};

export const membershipTiersOrderedByAscendingPermissions: readonly MembershipTier[] = [
  'starter',
  'premium',
  'unlimited',
] as const;

export function isUserOfMembershipTier(user: User | undefined, membershipTier: MembershipTier) {
  return user?.membershipTier == membershipTier;
}

export function isUserAtLeastOfMembershipTier(user: User | undefined, membershipTier: MembershipTier) {
  if (!user?.membershipTier) {
    return false;
  }
  return isMembershipTierAtLeast(membershipTier)(user.membershipTier);
}

export function getLimitForMembershipTierPermissionCategory(
  user: User | undefined,
  membershipTierLimitType: MembershipTierLimitType
): number {
  if (!user?.membershipTier) return 0;

  const membershipTierPermissionCategoryObject = membershipTierLimits[membershipTierLimitType];
  if (!membershipTierPermissionCategoryObject) return Infinity;

  const membershipTierPermissionCategoryLimit = membershipTierPermissionCategoryObject[user.membershipTier];
  if (!membershipTierPermissionCategoryLimit) return Infinity;

  return membershipTierPermissionCategoryLimit;
}

export function isFeatureEnabledForMembershipTier(
  user: User | undefined,
  membershipTierFeature: MembershipTierFeature
): boolean {
  if (!user?.membershipTier) return false;
  const featureConfig = membershipTierLimits[membershipTierFeature];
  if (!featureConfig) return true;

  return featureConfig[user.membershipTier];
}

export function isMembershipTierAtLeast(minimalRequiredMembershipTier: MembershipTier) {
  const indexOfRequiredTier = membershipTiersOrderedByAscendingPermissions.indexOf(minimalRequiredMembershipTier);
  return (membershipTier: MembershipTier) =>
    membershipTiersOrderedByAscendingPermissions.indexOf(membershipTier) >= indexOfRequiredTier;
}
