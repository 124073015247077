import {
  CollectionOverallStats,
  CollectionOverallStatValues,
} from '../../../../sci-ui-components/types/collectionOverallStats';
import { ApiCollectionStats, ApiCollectionCardsStats } from '../../types';

export default function collectionOverallStatsFromApi(apiOverallStats: ApiCollectionStats): CollectionOverallStats {
  return {
    custom: collectionOverallStatValuesFromApi(apiOverallStats.customCardsStats),
    nonCustom: collectionOverallStatValuesFromApi(apiOverallStats.cardsStats),
  };
}

function collectionOverallStatValuesFromApi(apiStats: ApiCollectionCardsStats): CollectionOverallStatValues {
  return {
    collectiblesOwned: apiStats.cardsOwned,
    collectiblesSold: apiStats.cardsSold,
    collectiblesWOPricePaid: apiStats.cardsWOPricePaid,
    collectionNetValueExcluded: apiStats.collectionNetValueExcluded,
    netProfitOrLoss: apiStats.netProfitOrLoss,
    netValue: apiStats.netValue,
    saleFees: apiStats.totalFees,
    totalPaid: apiStats.totalPaid,
    totalSales: apiStats.totalSales,
    totalSoldPurchasePrice: apiStats.totalSoldPurchasePrice,
    totalValue: apiStats.totalValue,
    uniqueItemsOwned: apiStats.uniqueItemsOwned,
  };
}
