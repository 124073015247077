import { useRouter } from 'next/router';
import useStableFunctionIdentity from '../../sci-ui-components/hooks/useStableFunctionIdentity';
import { DistributiveOmit } from '../../utils/tsUtils';
import { trackEvent as trackEventFn, AnalyticsEvent } from '../../services/firebase/analytics';
import useUser from '../user/useUser';

export default function useAnalytics() {
  const { data: user } = useUser();
  const { pathname } = useRouter();
  const membershipTier = user?.membershipTier;
  const userId = user?.id;
  const trackEvent = useStableFunctionIdentity(
    (params: DistributiveOmit<AnalyticsEvent, 'membershipTier' | 'userId' | 'pathname'>) => {
      const eventParams: AnalyticsEvent = {
        ...params,
        membershipTier,
        userId,
        pathname,
      };
      trackEventFn(eventParams);
    }
  );

  return { trackEvent };
}
