import { CollectionOverallStatValues } from '../../../sci-ui-components/types/collectionOverallStats';
import sumFields from '../../../utils/sumFields';

export default function combineCollectionOverallStatValues(stats: (CollectionOverallStatValues | null | undefined)[]) {
  return stats.reduce<CollectionOverallStatValues>((acc, stat) => {
    if (stat) {
      return sumFields(acc, stat, [
        'collectiblesOwned',
        'collectiblesSold',
        'collectiblesWOPricePaid',
        'collectionNetValueExcluded',
        'netProfitOrLoss',
        'netValue',
        'saleFees',
        'totalPaid',
        'totalSales',
        'totalSoldPurchasePrice',
        'totalValue',
        'uniqueItemsOwned',
      ]);
    }
    return acc;
  }, createEmptyCollectionOverallStatValues());
}

export function createEmptyCollectionOverallStatValues(): CollectionOverallStatValues {
  return {
    collectiblesOwned: 0,
    collectiblesSold: 0,
    collectiblesWOPricePaid: 0,
    collectionNetValueExcluded: 0,
    netProfitOrLoss: 0,
    netValue: 0,
    saleFees: 0,
    totalPaid: 0,
    totalSales: 0,
    totalSoldPurchasePrice: 0,
    totalValue: 0,
    uniqueItemsOwned: 0,
  };
}
