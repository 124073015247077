import { useMemo } from 'react';
import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { getCollectibleFavorites, GetCollectibleFavoritesResponse } from '../../services/sciApi/favorites';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import { ApiError } from '../../utils/api';
import { FavoriteCollectible } from '../../sci-ui-components/types/favoriteCollectible';
import useAuth from '../auth/useAuth';
import useFavoriteCollectibleCategoriesByType from './useFavoriteCollectibleCategoriesByType';

interface UseFavoriteCollectiblesByCategoryAndTypeParams {
  collectibleType: CollectibleType;
  categoryId: number;
  limit: number;
}
export const favoritesByCategoryKeyPrefix = 'favorites-by-category';
const getFavoriteCollectiblesByCategoryAndTypeKey = (params: UseFavoriteCollectiblesByCategoryAndTypeParams) => [
  favoritesByCategoryKeyPrefix,
  params,
];
type FavoriteCollectiblesByCategoryAndTypeKey = ReturnType<typeof getFavoriteCollectiblesByCategoryAndTypeKey>;

export function useFavoriteCollectiblesByCategoryAndType(
  { collectibleType, categoryId, limit }: UseFavoriteCollectiblesByCategoryAndTypeParams,
  options: UseInfiniteQueryOptions<
    GetCollectibleFavoritesResponse,
    ApiError,
    GetCollectibleFavoritesResponse,
    GetCollectibleFavoritesResponse,
    FavoriteCollectiblesByCategoryAndTypeKey
  > = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useInfiniteQuery(
    getFavoriteCollectiblesByCategoryAndTypeKey({ collectibleType, categoryId, limit }),
    async ({ pageParam = 0, signal }) => {
      const response = await getCollectibleFavorites(
        {
          collectibleType,
          categoryId,
          offset: pageParam,
          limit,
        },
        signal
      );
      return response;
    },
    {
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
      ...options,
      enabled: !!isLoggedIn && (options?.enabled ?? true),
      getNextPageParam: (lastPage, allPages) => {
        const totalItems = allPages.reduce((acc, { favorites }) => acc + favorites.length, 0);
        if (totalItems >= lastPage.totalCount || !lastPage?.favorites?.length) {
          return null;
        }
        return totalItems;
      },
    }
  );

  return queryResult;
}

interface UseFavoriteCollectiblesByTypeParams {
  collectibleType: CollectibleType;
  categoryIds?: number[];
  searchText?: string | null;
  sportId?: number | null;
  limit: number;
}
export const favoritesKeyPrefix = 'favorites';
type FavoriteCollectiblesByTypeKey = [typeof favoritesKeyPrefix, UseFavoriteCollectiblesByTypeParams, number[]];
interface FavoriteCollectiblesByTypeFnReturn {
  offset: number;
  items: FavoriteCollectible[];
  totalCount: number;
}

export function useFavoriteCollectiblesByType(
  params: UseFavoriteCollectiblesByTypeParams,
  options: UseInfiniteQueryOptions<
    FavoriteCollectiblesByTypeFnReturn,
    ApiError,
    FavoriteCollectiblesByTypeFnReturn,
    FavoriteCollectiblesByTypeFnReturn,
    FavoriteCollectiblesByTypeKey
  > = {}
) {
  const { collectibleType, categoryIds, searchText, sportId, limit } = params;
  const { data: categories, isLoading: isLoadingCategories } = useFavoriteCollectibleCategoriesByType({
    collectibleType,
  });

  const availableCategoryIds = categories?.map((c) => c.id) ?? [];
  const categoryIdsToQuery = availableCategoryIds.filter((id) => !categoryIds?.length || categoryIds.includes(id));

  const queryResult = useInfiniteQuery(
    [favoritesKeyPrefix, params, categoryIdsToQuery],
    async ({ pageParam = 0, signal }) => {
      const responses = await Promise.all(
        categoryIdsToQuery.map((categoryId) =>
          getCollectibleFavorites(
            {
              collectibleType,
              categoryId,
              searchText,
              sportId,
              offset: pageParam,
              limit,
            },
            signal
          )
        )
      );

      return responses.reduce<FavoriteCollectiblesByTypeFnReturn>(
        (acc, response) => {
          acc.items = acc.items.concat(response.favorites);
          acc.totalCount += response.totalCount;
          return acc;
        },
        {
          offset: pageParam,
          items: [],
          totalCount: 0,
        }
      );
    },
    {
      staleTime: 1000 * 60 * 60 * 1, // 1 hour
      ...options,
      enabled: !isLoadingCategories && (options?.enabled ?? true),
      getNextPageParam: (lastPage) => {
        const lastPageLength = lastPage?.items?.length ?? 0;
        if (!lastPageLength || lastPageLength < limit) {
          return null;
        }
        return lastPage.offset + limit;
      },
    }
  );

  const allItems = useMemo(
    () => queryResult.data?.pages?.reduce<FavoriteCollectible[]>((acc, page) => acc.concat(page.items ?? []), []) ?? [],
    [queryResult.data]
  );

  return {
    ...queryResult,
    isLoading: isLoadingCategories || queryResult.isLoading,
    allItems,
  };
}
