import { useCallback, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { aggregateCollectionItemsStats, CollectionItemsGroup } from '@sportscardinvestor/collection-helpers';
import { CustomCollectible, CollectionItem } from '@sportscardinvestor/schemas';
import {
  CollectibleType,
  collectibleTypes as allCollectibleTypes,
} from '../../sci-ui-components/types/collectibleType';
import {
  mmApiClient,
  MmApiInput,
  MmApiOutput,
  useAuthenticatedMMAPIQuery,
  MmApiQueryOptions,
  useAuthenticatedMMAPIQueries,
} from '@/services/mmApiX';
import useStableFunctionIdentity from '@/sci-ui-components/hooks/useStableFunctionIdentity';

export type { CollectionItem, Collectible, CustomCollectible } from '@sportscardinvestor/schemas';
export type { CollectionItemsGroup } from '@sportscardinvestor/collection-helpers';
export type UseListCollectionItemsInput = Exclude<MmApiInput['private']['collection']['listCollectionItems'], void>;
export type UseListCollectionItemsOutput = MmApiOutput['private']['collection']['listCollectionItems'];
export type CollectionStats = Pick<
  CollectionItem,
  | 'currentValuePerItem'
  | 'currentValueTotal'
  | 'gradingPricePerItem'
  | 'gradingPriceTotal'
  | 'netProfitPerItem'
  | 'netProfitTotal'
  | 'netProfitPercentage'
  | 'netValuePerItem'
  | 'netValuePercentage'
  | 'netValueTotal'
  | 'purchasePricePerItem'
  | 'purchasePriceTotal'
  | 'soldFeesPerItem'
  | 'soldFeesTotal'
  | 'soldPricePerItem'
  | 'soldPriceTotal'
  | 'totalGainedPerItem'
  | 'totalGainedTotal'
  | 'totalPriceOfOwnershipPerItem'
  | 'totalPriceOfOwnershipTotal'
  | 'quantity'
> & {
  datesPurchased: string[];
  datesGraded: string[];
  datesSold: string[];
};
export const useListCollectionItemsKeyPrefix = 'private.collection.listCollectionItems' as const;
export type UseListCollectionItemsKey = [typeof useListCollectionItemsKeyPrefix, UseListCollectionItemsInput];
export const getQueryKey = (input: UseListCollectionItemsInput): UseListCollectionItemsKey => [
  useListCollectionItemsKeyPrefix,
  input,
];

export function useListCollectionItems(
  input: UseListCollectionItemsInput,
  options?: MmApiQueryOptions<UseListCollectionItemsOutput, UseListCollectionItemsKey>
) {
  const result = useAuthenticatedMMAPIQuery(
    getQueryKey(input),
    async () => mmApiClient.private.collection.listCollectionItems.query(input),
    options
  );

  return result;
}

function makeInputVariations({
  collectibleTypes,
  sold,
  custom,
}: {
  collectibleTypes: Readonly<CollectibleType[]>;
  sold?: boolean;
  custom?: boolean;
}): UseListCollectionItemsInput[] {
  return collectibleTypes.reduce<UseListCollectionItemsInput[]>((acc, collectibleType) => {
    (typeof sold === 'boolean' ? [sold] : [true, false]).forEach((sold) => {
      (typeof custom === 'boolean' ? [custom] : [true, false]).forEach((custom) => {
        acc.push({
          filters: {
            collectibleType,
            ...(sold ? { soldOnly: true } : { nonSoldOnly: true }),
            ...(custom ? { customOnly: true } : { nonCustomOnly: true }),
          },
        });
      });
    });
    return acc;
  }, []);
}

export function useListAllCollectionItems(
  {
    collectibleTypes = allCollectibleTypes,
    sold,
    custom,
  }: {
    collectibleTypes?: Readonly<CollectibleType[]>;
    sold?: boolean;
    custom?: boolean;
  },
  options?: MmApiQueryOptions<UseListCollectionItemsOutput, UseListCollectionItemsKey>
) {
  const results = useAuthenticatedMMAPIQueries(
    makeInputVariations({
      collectibleTypes,
      sold,
      custom,
    }).map((input) => ({
      queryFn: async () => mmApiClient.private.collection.listCollectionItems.query(input),
      queryKey: getQueryKey(input),
      options,
    }))
  );
  const isLoading = results.some((qr) => qr.isLoading);
  const isError = results.some((qr) => qr.isError);
  const error = results.find((qr) => qr.error);
  const refetch = useStableFunctionIdentity(() => {
    results.forEach(({ refetch }) => refetch());
  });

  const items = useMemo(() => {
    return (
      results.reduce<CollectionItem[]>((acc, { data }) => {
        data?.items?.forEach((item) => {
          acc.push(item);
        });
        return acc;
      }, []) ?? []
    );
  }, [results]);

  return {
    items,
    isLoading,
    isError,
    error,
    refetch,
  };
}

export function useFetchListCollectionItems() {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);

  const fetchListCollectionItems = useCallback(
    async (input: UseListCollectionItemsInput) => {
      const key = getQueryKey(input);
      const cached = queryClient.getQueryData<UseListCollectionItemsOutput>(key, {
        stale: false,
        exact: true,
      });
      if (cached) {
        return cached;
      }
      try {
        setIsLoading(true);
        const fetched = await mmApiClient.private.collection.listCollectionItems.query(input);
        queryClient.setQueryDefaults(key, {
          queryFn: () => mmApiClient.private.collection.listCollectionItems.query(input),
        });
        queryClient.setQueryData(key, fetched, {});
        return fetched;
      } finally {
        setIsLoading(false);
      }
    },
    [queryClient]
  );

  return {
    fetchListCollectionItems,
    isLoading,
  };
}

export function useCollectibleInCollection(
  {
    collectibleType,
    collectibleId,
    isCustom,
  }: {
    collectibleType: CollectibleType;
    collectibleId: number | null | undefined;
    isCustom: boolean;
  },
  {
    enabled,
  }: {
    enabled?: boolean;
  } = {}
): {
  collectibleInCollection: CollectionItemsGroup | null;
  isLoading: boolean;
} {
  const { data, isLoading } = useListCollectionItems(
    {
      filters: { collectibleType },
    },
    {
      enabled: !!collectibleId && enabled !== false,
    }
  );

  const collectibleInCollection = useMemo<CollectionItemsGroup | null>(() => {
    if (!collectibleId) {
      return null;
    }
    const collectibleCollectionItems = data?.items?.filter(
      (c) => c.collectibleId === collectibleId && c.isCustom === isCustom
    );
    if (collectibleCollectionItems?.length) {
      const firstItem = collectibleCollectionItems[0];
      return {
        isCustom: firstItem.isCustom,
        collectible: firstItem.collectible,
        customCollectible: firstItem.customCollectible,
        stats: aggregateCollectionItemsStats(collectibleCollectionItems),
        items: collectibleCollectionItems,
      };
    }
    return null;
  }, [data?.items, collectibleId, isCustom]);

  return {
    isLoading,
    collectibleInCollection,
  };
}

export function useCollectionItem({
  collectionItemId,
  collectibleType,
}: {
  collectionItemId: number | null | undefined;
  collectibleType: CollectibleType;
}): {
  collectionItem: CollectionItem | null;
  isLoading: boolean;
} {
  const { data, isLoading } = useListCollectionItems(
    {
      filters: { collectibleType },
    },
    {
      enabled: !!collectionItemId,
    }
  );

  const collectionItem = useMemo<CollectionItem | null>(() => {
    if (!collectionItemId) {
      return null;
    }
    return data?.items?.find((c) => c.id === collectionItemId) ?? null;
  }, [data?.items, collectionItemId]);

  return {
    isLoading,
    collectionItem,
  };
}

export function useCustomCollectible(
  {
    collectibleType,
    collectibleId,
  }: {
    collectibleType: CollectibleType;
    collectibleId: number | null;
  },
  {
    enabled,
  }: {
    enabled?: boolean;
  } = {}
): {
  customCollectible: CustomCollectible | null;
  isLoading: boolean;
} {
  const { data, isLoading } = useListCollectionItems(
    {
      filters: { collectibleType },
    },
    {
      enabled: !!collectibleId && enabled !== false,
    }
  );

  const customCollectible = useMemo<CustomCollectible | null>(() => {
    if (!collectibleId) {
      return null;
    }
    const collectibleCollectionItem = data?.items?.find(
      (c) => c.collectibleId === collectibleId && c.isCustom === true
    );
    return collectibleCollectionItem?.customCollectible ?? null;
  }, [data?.items, collectibleId]);

  return {
    isLoading,
    customCollectible,
  };
}
