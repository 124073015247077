import qs from 'qs';
import {
  CollectibleType,
  isSealedWaxCardCollectibleType,
  isSportsCardCollectibleType,
} from '../../../sci-ui-components/types/collectibleType';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiCollectionDayStats, ApiCollectionItem, ApiCollectionStats, ApiPersonalCollectionRecord } from '../types';
import collectionDayStatsFromApi from './transformers/collectionDayStatsFromApi';
import collectionOverallStatsFromApi from './transformers/collectionOverallStatsFromApi';
import collectionItemFromApi from './transformers/collectionItemFromApi';
import { NoCategory } from './types';
import { ensureDataUrl } from 'sci-ui-components/utils/image';
import { CollectionDayStats } from 'sci-ui-components/types/collectionDayStats';
import { getISODatesInRange, treatUtcAsIfItIsLocal } from 'sci-ui-components/utils/date';
import { sortByStringField } from 'utils/sort';
import { CollectionItem } from 'sci-ui-components/types/collectionItem';
import { CollectionCategory } from 'sci-ui-components/types/collectionCategory';

export async function getCollectionStats(
  {
    collectibleType,
    categoryId,
  }: {
    collectibleType: CollectibleType;
    categoryId?: number | null;
  },
  signal: AbortSignal | undefined
) {
  const apiResult = await authenticatedFetchFromSciApi<ApiCollectionStats>(
    `/personalCollection/stats${
      categoryId ? `/${categoryId === NoCategory ? 'uncategorized' : categoryId}` : ''
    }${qs.stringify(
      {
        onlyCards: isSportsCardCollectibleType(collectibleType),
      },
      {
        addQueryPrefix: true,
        skipNulls: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );
  return collectionOverallStatsFromApi(apiResult);
}

export async function getCollectionHistory({ type }: { type: CollectibleType }, signal: AbortSignal | undefined) {
  const apiResult = await authenticatedFetchFromSciApi<ApiCollectionDayStats[]>(
    `/collection-history${qs.stringify(
      {
        type: isSealedWaxCardCollectibleType(type) ? 'WAX' : undefined,
      },
      {
        addQueryPrefix: true,
        skipNulls: true,
      }
    )}`,
    {
      method: 'GET',
      signal,
    }
  );
  // NOTE: api returns "holes" in date -> need to make sure we havee an entry for eevery day even if it's values are null
  const sortedApiItems = sortByStringField(apiResult, (item) => item.day_timestamp, 'asc');
  if (!sortedApiItems.length) return [];
  const startDate = sortedApiItems[0].day_timestamp;
  const endDate = sortedApiItems[sortedApiItems.length - 1].day_timestamp;
  const allDates = getISODatesInRange([startDate, endDate]);
  const apiResultsMap = new Map(apiResult.map((apiResult) => [apiResult.day_timestamp, apiResult]));
  const result = allDates.reduce<CollectionDayStats[]>((acc, isoDate) => {
    if (apiResultsMap.has(isoDate)) {
      acc.push(collectionDayStatsFromApi(apiResultsMap.get(isoDate)!));
    } else if (acc.length) {
      // NOTE: if data is missing take ROLLING values ONLY from PREV day
      const prevDayStats = acc[acc.length - 1];
      acc.push({
        isoDate,
        dailyProfit: null,
        dailySalesTotal: null,
        netValue: prevDayStats.netValue,
        profit: prevDayStats.profit,
        totalValue: prevDayStats.totalValue,
      });
    } else {
      acc.push({
        isoDate,
        dailyProfit: null,
        dailySalesTotal: null,
        netValue: null,
        profit: null,
        totalValue: null,
      });
    }
    return acc;
  }, []);
  return result;
}

export interface AddOrUpdateCollectionItemParams {
  collectionItemId?: number;
  collectibleId: number | null;
  purchaseDate?: string | null;
  purchasePricePerItem?: number | null;
  gradingPricePerItem?: number | null;
  quantity: number;
  collectibleType: CollectibleType;
  isCustom: boolean;
}

export async function addOrUpdateCollectionItem({
  collectionItemId,
  collectibleId,
  quantity,
  purchaseDate,
  purchasePricePerItem,
  gradingPricePerItem,
  collectibleType,
  isCustom,
  mode,
}: AddOrUpdateCollectionItemParams & { mode: 'add' | 'update' }): Promise<CollectionItem> {
  if (mode === 'update' && !collectionItemId) throw new Error('collectionItemId is required for update');
  const urlSlug = '/personalCollection';
  const url = mode === 'add' ? urlSlug : `${urlSlug}/${collectionItemId}`;
  const addFields = {
    onlyCards: isSportsCardCollectibleType(collectibleType),
    [getApiCollectibleIdField(collectibleType, isCustom)]: collectibleId,
  };

  const purchaseDateWithTimestamp = purchaseDate ? treatUtcAsIfItIsLocal(purchaseDate) : null;

  const apiResponse = await authenticatedFetchFromSciApi<ApiPersonalCollectionRecord>(url, {
    method: mode === 'add' ? 'POST' : 'PUT',
    body: JSON.stringify({
      ...(mode === 'add' ? addFields : {}),
      date_purchased: purchaseDateWithTimestamp,
      purchase_price_per_card: purchasePricePerItem,
      grading_price_per_card: gradingPricePerItem ?? undefined,
      quantity,
    }),
  });

  return collectionItemFromApi(apiResponse, { collectibleType, isCustom: false });
}

export interface UpdateCollectionItemPurchaseDetailsParams {
  collectionItemId: number;
  purchaseDate: string;
  purchasePricePerItem: number;
  gradingPricePerItem?: number | null;
  quantity: number;
  collectibleType: CollectibleType;
  isCustom: boolean;
}

export async function updateCollectionItemPurchaseDetails(params: UpdateCollectionItemPurchaseDetailsParams) {
  return addOrUpdateCollectionItem({
    ...params,
    collectibleId: null,
    mode: 'update',
  });
}

function getApiCollectibleIdField(collectibleType: CollectibleType, isCustom: boolean) {
  if (isCustom) {
    return isSportsCardCollectibleType(collectibleType) ? 'custom_card_id' : 'custom_sealed_wax_id';
  } else {
    return isSportsCardCollectibleType(collectibleType) ? 'card_id' : 'sealed_wax_id';
  }
}

export interface AddOrUpdateCustomCollectibleProps {
  sportName?: string | null;
  playerId?: number | null;
  playerName?: string | null;
  cardSetYear?: string | null;
  cardSetName?: string | null;
  cardSetId?: number | null;
  boxTypeName?: string | null;
  gradeName?: string | null;
  quantity?: number;
  variationName?: string | null;
  purchasePricePerItem?: number | null;
  gradingPricePerItem?: number | null;
  purchaseDate?: string | null;
  collectibleType: CollectibleType;
  currentValue?: number | null;
  query?: string | null;
  customQuery?: string | null;
  collectibleId?: number;
  imageUrl?: string | null;
  cardNumber?: string | null;
  specificQualifier?: string | null;
}

export interface AddOrUpdateCustomCollectibleResponse {
  collectionItem: CollectionItem;
  message: string;
}

export async function addOrUpdateCustomCollectible({
  sportName,
  playerId,
  playerName, // TODO this never actually gets updated in the DB even though the API accepts it. Same in MM1
  cardSetYear,
  cardSetName,
  cardSetId,
  boxTypeName,
  gradeName,
  quantity,
  variationName,
  purchasePricePerItem,
  gradingPricePerItem,
  purchaseDate,
  collectibleType,
  query,
  customQuery,
  currentValue,
  mode,
  collectibleId,
  imageUrl,
  cardNumber,
  specificQualifier,
}: AddOrUpdateCustomCollectibleProps & { mode: 'add' | 'update' }): Promise<CollectionItem> {
  if (mode === 'update' && !collectibleId) throw new Error('collectibleId is required for update');

  const sealedWaxFields = { type: boxTypeName };
  const sportsCardFields = {
    player_id: playerId,
    player_name: playerName,
    variation: variationName,
    grade: gradeName,
    card_number: cardNumber,
    specific_qualifier: specificQualifier,
  };

  const collectibleTypeUrlSlug = isSportsCardCollectibleType(collectibleType) ? `/custom-cards` : `/custom-sealed-wax`;
  const url = mode === 'add' ? collectibleTypeUrlSlug : `${collectibleTypeUrlSlug}/${collectibleId}`;

  const imageDataUrl = imageUrl && mode === 'add' ? await ensureDataUrl(imageUrl) : imageUrl;

  const apiResponse = await authenticatedFetchFromSciApi<ApiPersonalCollectionRecord>(url, {
    method: mode === 'add' ? 'POST' : 'PUT',
    body: JSON.stringify({
      sport: sportName ? sportName : '',
      card_set_id: cardSetId,
      card_set_name: cardSetName,
      card_set_year: cardSetYear,
      ...(mode === 'add'
        ? {
            date_purchased: purchaseDate,
            purchase_price_per_card: purchasePricePerItem,
            grading_price_per_card: gradingPricePerItem,
            quantity: quantity,
          }
        : {}),
      query: query,
      custom_query: customQuery,
      // use the purchase price as current value if the current value is not defined
      price: !currentValue && currentValue !== 0 ? purchasePricePerItem : currentValue,
      photo: imageDataUrl,
      ...(isSportsCardCollectibleType(collectibleType) ? sportsCardFields : sealedWaxFields),
    }),
  });

  if (mode === 'add' && isSealedWaxCardCollectibleType(collectibleType)) {
    // NOTE: custom sealed waxes are not automatically added to collection by API. This should be fixed in new API
    const customSealedWaxApiRespinse = apiResponse as unknown as {
      card_set_id: number;
      card_set_name: string;
      card_set_year: number;
      createdAt: string;
      created_at: string;
      id: number; // custom sealed wax id
      last_price_update: number;
      last_price_updated_at: string;
      price: number;
      query: string;
      sport: string;
      type: null;
      updatedAt: string;
      updated_at: string;
    };
    return addOrUpdateCollectionItem({
      collectibleId: customSealedWaxApiRespinse.id,
      quantity: quantity ?? 1,
      collectibleType,
      isCustom: true,
      mode: 'add',
      purchaseDate,
      purchasePricePerItem,
      gradingPricePerItem,
    });
  }

  return collectionItemFromApi(apiResponse, { collectibleType, isCustom: true });
}

export interface UpdateCollectionItemCategoryParams {
  collectionItemId: number;
  collectionCategoryId?: number | null;
}

export async function updateCollectionItemCategory({
  collectionItemId,
  collectionCategoryId,
}: UpdateCollectionItemCategoryParams) {
  const isAdd = typeof collectionCategoryId === 'number';
  const addOrRemoveUrlPart = isAdd ? 'associate' : `disassociate/${collectionItemId}`;
  const url = `/collectionCategory/${addOrRemoveUrlPart}`;

  await authenticatedFetchFromSciApi(url, {
    method: isAdd ? 'POST' : 'DELETE',
    body: isAdd
      ? JSON.stringify({
          collection_category_id: collectionCategoryId,
          personal_collection_id: collectionItemId,
        })
      : undefined,
  });
}

export interface SellOrUpdateSoldCollectionItemParams {
  collectionItemId: number;
  salePricePerItem: number;
  saleDate: string;
  quantity: number;
  feesPerItem: number;
  category?: CollectionCategory;
  mode: 'edit' | 'add';
}

export interface SellOrUpdateSoldCollectionItemResponse {
  message: string;
}

export async function sellOrUpdateSoldCollectionItem({
  collectionItemId,
  salePricePerItem,
  saleDate,
  quantity,
  feesPerItem,
  category,
  mode,
}: SellOrUpdateSoldCollectionItemParams): Promise<SellOrUpdateSoldCollectionItemResponse> {
  const saleDateWithTimestamp = treatUtcAsIfItIsLocal(saleDate);
  const response = await authenticatedFetchFromSciApi(`/personalCollection/${collectionItemId}/sold`, {
    method: mode === 'add' ? 'POST' : 'PATCH',
    body: JSON.stringify({
      collectionId: collectionItemId,
      price: salePricePerItem,
      date: saleDateWithTimestamp,
      quantity,
      fees: feesPerItem,
      categoryId: category?.id,
    }),
  });

  if (response?.message !== 'Collection item has been sold')
    throw new Error(
      `${mode === 'add' ? 'Collection item was not marked as sold' : 'Collection item sale details were not updated'}`
    );
  const message = mode === 'add' ? response.message : 'Collection item sale details were updated';

  return {
    message,
  };
}

export interface UpdateCollectionItemSaleDetailsParams {
  collectionItemId: number;
  salePricePerItem: number;
  saleDate: string;
  quantity: number;
  feesPerItem: number;
}

export async function updateCollectionItemSaleDetails(params: UpdateCollectionItemSaleDetailsParams) {
  return sellOrUpdateSoldCollectionItem({
    ...params,
    mode: 'edit',
  });
}

export interface EditCollectionItemNoteParams {
  collectionItemId: number;
  note: string;
}

export interface EditCollectionItemNoteResponse {
  collectionItemId: number;
  note: string;
  message: string;
}

export async function editCollectionItemNote({
  collectionItemId,
  note,
}: EditCollectionItemNoteParams): Promise<EditCollectionItemNoteResponse> {
  const response = await authenticatedFetchFromSciApi(`/personalCollection/${collectionItemId}/note`, {
    method: 'PATCH',
    body: JSON.stringify({
      note,
    }),
  });

  if (response?.message !== 'Collection item note was updated') throw new Error(`Collection item note was not updated`);

  return {
    collectionItemId,
    note,
    message: 'Collection item note was updated',
  };
}

export interface DeleteCollectionItemParams {
  id: number;
}

export async function deleteCollectionItem({ id }: DeleteCollectionItemParams) {
  await authenticatedFetchFromSciApi<ApiCollectionItem>(`/personalCollection/${id}`, {
    method: 'DELETE',
  });
}

export async function addMultipleCollectionItems({
  items,
  collectibleType,
}: {
  items: (Omit<AddOrUpdateCollectionItemParams, 'collectibleType'> & {
    categoryId?: number | null;
  })[];
  collectibleType: AddOrUpdateCollectionItemParams['collectibleType'];
}): Promise<boolean> {
  const response = await authenticatedFetchFromSciApi<null[]>('/personalCollection/multiple', {
    method: 'POST',
    body: JSON.stringify({
      items: items.map(
        ({
          collectibleId,
          categoryId,
          quantity,
          purchaseDate,
          purchasePricePerItem,
          gradingPricePerItem,
          isCustom,
        }) => ({
          date_purchased: purchaseDate,
          purchase_price_per_card: purchasePricePerItem,
          grading_price_per_card: gradingPricePerItem,
          quantity,
          [getApiCollectibleIdField(collectibleType, isCustom)]: collectibleId,
          category_id: categoryId,
        })
      ),
    }),
  });

  return response.length > 0;
}

export interface ConvertGradeParams {
  collectionItemId: number;
  cards: Record<string, number>;
  gradingPricePerCard: number;
}

export const convertGrade = async ({ collectionItemId, cards, gradingPricePerCard }: ConvertGradeParams) => {
  await authenticatedFetchFromSciApi<{ ok: string }>(`/personalCollection/grade/${collectionItemId}`, {
    method: 'POST',
    body: JSON.stringify({ cards, gradingPricePerCard }),
  });
};
