import { useMemo, useState } from 'react';
import { useQueries, useQuery, useQueryClient, UseQueryOptions } from 'react-query';
import { getCollectibleById } from '../../services/sciApi/collectibles/index';
import { CollectibleType } from '../../sci-ui-components/types/collectibleType';
import { NonCustomCollectible } from '../../sci-ui-components/types/collectible';
import { ApiError } from '../../utils/api';
import useAuth from '../auth/useAuth';
import { MmApiInput, MmApiOutput, MmApiQueryOptions, mmApiClient, useAuthenticatedMMAPIQuery } from '@/services/mmApiX';

type QueryKey = ['collectible', CollectibleType, number | undefined, boolean | undefined];
const makeUseCollectibleKey: (params: {
  collectibleType: CollectibleType;
  id: number | undefined;
  isPublic?: boolean;
}) => QueryKey = ({ collectibleType, id, isPublic }) => ['collectible', collectibleType, id, isPublic];
export function useCollectible(
  {
    collectibleType,
    id,
    isPublic = false,
  }: {
    id: number | undefined;
    collectibleType: CollectibleType;
    isPublic?: boolean | undefined;
  },
  options: UseQueryOptions<NonCustomCollectible, ApiError, NonCustomCollectible, QueryKey> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResult = useQuery(
    makeUseCollectibleKey({
      collectibleType,
      id,
    }),
    ({ signal }) =>
      getCollectibleById(
        {
          collectibleType,
          id: id!,
          isPublic,
        },
        signal
      ),
    {
      staleTime: 1000 * 60 * 60 * 6, // 6 hours
      ...options,
      enabled: !!id && (isLoggedIn || isPublic) && (options?.enabled ?? true),
    }
  );

  return queryResult;
}

export function useCollectibles(
  {
    collectibleType,
    collectibleIds,
  }: {
    collectibleIds: number[];
    collectibleType: CollectibleType;
  },
  options: UseQueryOptions<NonCustomCollectible, ApiError, NonCustomCollectible, QueryKey> = {}
) {
  const { isLoggedIn } = useAuth();
  const queryResults = useQueries(
    collectibleIds.map((collectibleId) => ({
      ...options,
      queryKey: makeUseCollectibleKey({
        collectibleType,
        id: collectibleId,
      }),
      queryFn: () =>
        getCollectibleById({
          collectibleType,
          id: collectibleId,
          isPublic: false,
        }),
      enabled: isLoggedIn && (options?.enabled ?? true),
    }))
  );

  const isLoading = queryResults.some(({ isLoading }) => isLoading);
  const collectibles = useMemo(
    () =>
      queryResults.reduce<NonCustomCollectible[]>((acc, { data }) => {
        if (!!data) {
          acc.push(data);
        }
        return acc;
      }, []),
    [queryResults]
  );

  return {
    collectibles,
    isLoading,
    ...queryResults,
  };
}

/**
 * Exposes a function to fetch a collectible while still using react-query cache
 */
export function useFetchCollectible() {
  const queryClient = useQueryClient();
  const { isLoggedIn } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  async function fetchCollectibleById({ collectibleType, id }: { collectibleType: CollectibleType; id: number }) {
    const queryKey = makeUseCollectibleKey({
      collectibleType,
      id,
    });
    const cached = queryClient.getQueryData<NonCustomCollectible>(queryKey);
    if (cached) {
      return cached;
    }
    setIsLoading(true);
    try {
      const fetched = await getCollectibleById({
        collectibleType,
        id,
        isPublic: !isLoggedIn,
      });
      queryClient.setQueryData<NonCustomCollectible>(queryKey, fetched);
      return fetched;
    } finally {
      setIsLoading(false);
    }
  }

  return {
    isLoading,
    fetchCollectibleById,
  };
}

type UseFetchVariationsByCollectibleOutput =
  MmApiOutput['private']['collectibles']['filters']['availableVariationsForCard'];

export function useFetchVariationsByCollectible({
  subjectId,
  collectibleSetId,
  gradeId,
  options = {},
}: {
  subjectId: number;
  collectibleSetId: number;
  gradeId: number;
  options?: MmApiQueryOptions<
    UseFetchVariationsByCollectibleOutput,
    [string, MmApiInput['private']['collectibles']['filters']['availableVariationsForCard']]
  >;
}) {
  const result = useAuthenticatedMMAPIQuery(
    [
      'private.collectibles.filters.availableVariationsForCard',
      { player_id: subjectId, set_id: collectibleSetId, grade_id: gradeId },
    ],
    () =>
      mmApiClient.private.collectibles.filters.availableVariationsForCard.query({
        player_id: subjectId,
        set_id: collectibleSetId,
        grade_id: gradeId,
      }),
    { ...options }
  );

  return result;
}

type UseFetchGradesByCollectibleOutput = MmApiOutput['private']['collectibles']['filters']['availableGradesForCard'];

export function useFetchGradesByCollectible({
  subjectId,
  collectibleSetId,
  variationId,
  cardNumber,
  specificQualifier,
  options = {},
}: {
  subjectId: number;
  collectibleSetId: number;
  variationId: number;
  cardNumber: string | null;
  specificQualifier: string | null;
  options?: MmApiQueryOptions<
    UseFetchGradesByCollectibleOutput,
    [string, MmApiInput['private']['collectibles']['filters']['availableGradesForCard']]
  >;
}) {
  const input = {
    player_id: subjectId,
    set_id: collectibleSetId,
    variation_id: variationId,
    card_number: cardNumber,
    specific_qualifier: specificQualifier,
  };

  const result = useAuthenticatedMMAPIQuery(
    ['private.collectibles.filters.availableGradesForCard', input],
    () => mmApiClient.private.collectibles.filters.availableGradesForCard.query(input),
    { ...options }
  );

  return result;
}

type UseFetchAvailableBoxTypesForSealedOutput =
  MmApiOutput['private']['collectibles']['filters']['availableBoxTypesForSealed'];

export function useFetchAvailableBoxTypesForSealed({
  boxSetId,
  options = {},
}: {
  boxSetId: number;
  options?: MmApiQueryOptions<
    UseFetchAvailableBoxTypesForSealedOutput,
    [string, MmApiInput['private']['collectibles']['filters']['availableBoxTypesForSealed']]
  >;
}) {
  const input = {
    set_id: boxSetId,
  };

  const result = useAuthenticatedMMAPIQuery(
    ['private.collectibles.filters.availableBoxTypesForSealed', input],
    () => mmApiClient.private.collectibles.filters.availableBoxTypesForSealed.query(input),
    { ...options }
  );

  return result;
}
