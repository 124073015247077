import { CollectibleType } from '../../../sci-ui-components/types/collectibleType';
import authenticatedFetchFromSciApi from '../authenticatedFetchFromSciApi';
import { ApiCollectible } from '../types';
import getCollectibleTypeParam from '../utils/getCollectibleTypeParam';
import fetchFromSciApi from '../fetchFromSciApi';
import collectibleFromApi from './transformers/collectibleFromApi';
import { NonCustomCollectible } from '@/sci-ui-components/types/collectible';

export async function getCollectibleById(
  {
    id,
    collectibleType,
    isPublic = false,
  }: { id: number; collectibleType: CollectibleType; isPublic: boolean | undefined },
  signal?: AbortSignal
) {
  const type = getCollectibleTypeParam(collectibleType);

  const apiItem = isPublic
    ? await fetchFromSciApi<ApiCollectible>(`/${type}/${id}/public?excludeResults=true`, {
        method: 'GET',
        signal,
      })
    : await authenticatedFetchFromSciApi<ApiCollectible>(`/${type}/${id}?excludeResults=true`, {
        method: 'GET',
        signal,
      });

  return collectibleFromApi(apiItem, collectibleType) as NonCustomCollectible;
}
